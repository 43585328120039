import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import Carousel from '../components/Carousel'
import {
  breakpoint,
  desktopGutter,
  GridList,
  GridListItem,
  gutter,
} from '../components/GridList'
import HoneybookInquiryForm from '../components/HoneybookInquiryForm'
import Layout from '../components/Layout'
import PagePadding from '../components/PagePadding'
import Seo from '../components/Seo'
import SuiteCard from '../components/SuiteCard'
import { ISemiCustomSuite } from '../types/SemiCustomSuite'

type Props = {
  pageContext: {
    suite: ISemiCustomSuite
  }
}

function SemiCustomSuitePage({ pageContext }: Props) {
  const {
    suite: { name, description, additionalImages = [] },
  } = pageContext
  const title = `The ${name} Suite`
  const cards = useMemo(
    () =>
      cardTypes.map(cardType => {
        return {
          type: cardType,
          image:
            pageContext.suite[`${cardType}CardImage`].asset.gatsbyImageData,
          alt: pageContext.suite[`${cardType}CardImage`].alt,
          caption: getCaption(cardType),
        }
      }),
    [pageContext.suite]
  )

  return (
    <Layout pageTitle={title}>
      <Seo title={title} description={description} />
      <Content>
        <GridList>
          {cards.map(card => {
            return (
              <GridListItem key={card.type}>
                <SuiteCard
                  image={card.image}
                  alt={card.alt}
                  caption={card.caption}
                />
              </GridListItem>
            )
          })}
        </GridList>
        {additionalImages?.length && (
          <CarouselWrapper>
            <Carousel autoplay>
              {additionalImages.map(imageData => {
                return (
                  <CarouselImage
                    key={imageData.asset.assetId}
                    image={imageData.asset.gatsbyImageData}
                    alt={imageData.alt}
                  />
                )
              })}
            </Carousel>
          </CarouselWrapper>
        )}
        <HoneybookInquiryForm />
      </Content>
    </Layout>
  )
}

export default SemiCustomSuitePage

const Content = styled(PagePadding)`
  padding-top: 3rem;
  padding-bottom: 3rem;
  max-width: 1020px;
  margin: 0 auto;
`

const cardTypes = ['invitation', 'rsvp', 'details', 'saveTheDate']

const getCaption = (cardType: string) => {
  const captions = {
    invitation: 'Invitation Card',
    rsvp: 'RSVP Card',
    details: 'Details Card',
    saveTheDate: 'Save the Date Card',
  }
  return captions[cardType]
}

const CarouselWrapper = styled.div`
  padding: ${gutter} 0;

  @media (min-width: ${breakpoint}) {
    padding: ${desktopGutter} 0;
  }
`

const CarouselImage = styled(GatsbyImage)``
