import VisuallyHidden from '@reach/visually-hidden'
import NukaCarousel from 'nuka-carousel'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

function Carousel({ children, ...otherProps }) {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [disableControls, setDisableControls] = useState(false)

  const handleBeforeSlide = useCallback(() => {
    setDisableControls(true)
  }, [setDisableControls])

  const handleAfterSlide = useCallback(
    slideIndex => {
      setDisableControls(false)
      setCurrentSlideIndex(slideIndex)
    },
    [setDisableControls, setCurrentSlideIndex]
  )

  return (
    <CarouselWrapper>
      <NukaCarousel
        autoplayInterval={7500}
        speed={1000}
        slideIndex={currentSlideIndex}
        renderCenterLeftControls={null}
        renderCenterRightControls={null}
        renderBottomCenterControls={null}
        wrapAround
        beforeSlide={handleBeforeSlide}
        afterSlide={handleAfterSlide}
        {...otherProps}
      >
        {children}
      </NukaCarousel>
      {children.length > 1 && (
        <Controls>
          {children.map((child, i) => (
            <Control key={i}>
              <Dot
                type="button"
                disabled={disableControls}
                onClick={() => !disableControls && setCurrentSlideIndex(i)}
                isActive={currentSlideIndex === i}
              >
                <VisuallyHidden>View image {i + 1}</VisuallyHidden>
              </Dot>
            </Control>
          ))}
        </Controls>
      )}
    </CarouselWrapper>
  )
}

export default Carousel

const CarouselWrapper = styled.div`
  overflow: hidden;
`

const Controls = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
`

const Control = styled.li`
  margin: 0 0.25rem;
  padding: 0;
  display: flex;
`

const Dot = styled.button<{ isActive: boolean }>`
  height: 0.5rem;
  width: 0.5rem;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  border: none;
  transition: background 0.3s;
  background: ${({ isActive, theme }) =>
    isActive ? theme.colors.secondary : theme.colors.muted};
`
