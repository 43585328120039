import styled from 'styled-components'

export const gutter = '2rem'
export const desktopGutter = '3rem'
export const breakpoint = '768px'

export const GridList = styled.ul`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: -${gutter};

  @media (min-width: ${breakpoint}) {
    flex-flow: row wrap;
    margin-right: -${desktopGutter};
    margin-bottom: -${desktopGutter};
  }
`

export const GridListItem = styled.li`
  padding-bottom: ${gutter};

  @media (min-width: ${breakpoint}) {
    padding-right: ${desktopGutter};
    padding-bottom: ${desktopGutter};
    width: 50%;
  }
`
