import React from 'react'
import styled from 'styled-components'
import Anchor from './Anchor'
import Paragraph from './Paragraph'

interface Props {
  className?: string
}

function HoneybookInquiryForm({ className }: Props) {
  return (
    <div className={className}>
      <Paragraph align="center">
        Can't see the form below?{' '}
        <Anchor href="/inquiryform.html" target="_blank">
          Click here
        </Anchor>{' '}
        to open it in a new window.
      </Paragraph>
      <Iframe
        id="honeybook_iframe"
        title="Wedding Stationery Inquiry Form"
        src="/inquiryform.html"
      />
    </div>
  )
}

export default HoneybookInquiryForm

const Iframe = styled.iframe`
  border: none;
  width: 100%;
  height: 7500px;

  @media (min-width: 750px) {
    height: 8000px;
  }
`
