import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import SerifText from './SerifText'

type Props = {
  image: IGatsbyImageData
  alt: string
  caption: string
}

function SuiteCard({ image, alt, caption }: Props) {
  return (
    <Figure>
      <GatsbyImage image={image} alt={alt || ''} />
      <Caption as="figcaption">{caption}</Caption>
    </Figure>
  )
}

export default SuiteCard

const Figure = styled.figure`
  margin: 0;
`

const Caption = styled(SerifText)`
  font-size: 1rem;
  text-align: center;
  padding-top: 0.5rem;
`
